class Cart {
    constructor(selector, options) {
        this.selector = selector;
        this.options = options;
        this.url = '/wp-admin/admin-ajax.php';
        this.actions = {
            add: 'nt_add_product',
            remove: 'nt_remove_product',
            increase: 'nt_increase_qty_cart',
            decrease: 'nt_decrease_qty_cart'
        }

        this.removeFromCart = this.removeFromCart.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.increaseCartQty = this.increaseCartQty.bind(this);
        this.decreaseCartQty = this.decreaseCartQty.bind(this);
        this.closeSide = this.closeSide.bind(this);

        this.productContainer = this.selector.querySelector('[data-element="product-container"]');

        this._connectActions();
    }

    removeFromCart(event) {
        event.preventDefault();
        let button = event.target.closest('[data-id]');
        let product_id = button.getAttribute('data-id');
        if(product_id !== null && typeof product_id !== 'undefined') {
            this._removeProduct(product_id).then(res => {
                if(this.options.hasOwnProperty('afterRemoveSuccess')) this.options.afterRemoveSuccess()
            });
        }
    }
    addToCart(event) {
        event.preventDefault();
        let button = event.target.closest('[data-product_id]');
        let product_id = button.getAttribute('data-product_id');
        if(product_id !== null && typeof product_id !== 'undefined') {
            this._addProduct(product_id).then(res => {
                if(this.options.hasOwnProperty('afterAddSuccess')) this.options.afterAddSuccess()

                if (res) {
                    buttonClickHandler(product_id, indexOfProduct(button), "add_to_cart")
                }
            });
        }
    }

    increaseCartQty(event) {
        event.preventDefault();
        let button = event.target.closest('[data-cart-item-key]');
        let cart_item_key = button.getAttribute('data-cart-item-key');
        let product_id = button.getAttribute('data-product_id');
        if(cart_item_key !== null && typeof cart_item_key !== 'undefined') {
            this._increaseQuantity(cart_item_key).then(res => {
                if(this.options.hasOwnProperty('afterIncreaseSuccess')) this.options.afterIncreaseSuccess()

                if (res) {
                    buttonClickHandler(product_id, indexOfProduct(button), "add_to_cart")
                }
            });
        }
    }
    decreaseCartQty(event) {
        event.preventDefault();
        let button = event.target.closest('[data-cart-item-key]');
        let cart_item_key = button.getAttribute('data-cart-item-key');
        let product_id = button.getAttribute('data-product_id');
        if(cart_item_key !== null && typeof cart_item_key !== 'undefined') {
            this._decreaseQuantity(cart_item_key).then(res => {
                if(this.options.hasOwnProperty('afterDecreaseSuccess')) this.options.afterDecreaseSuccess()

                if (res) {
                    buttonClickHandler(product_id, indexOfProduct(button), "remove_from_cart")
                }

            });
        }
    }

    closeSide(event) {
        event.preventDefault();
        this.selector.classList.remove('open');
        document.body.classList.remove('noscroll');
    }

    _addProduct(product_id){
        return this._getFromApi('add', product_id).then(response => {
            if(response.status === 'success') {
                this._refreshSide(response.mini);
                this.options.updateCount(response.count);
                this.options.showSuccess(response.modal);

                return true;
            } else {
                this.options.showError(response.message);
                return false;
            }
        }).catch(error => {
            console.log(error);
            return false;
        });
    }

    _removeProduct(product_id){
        return this._getFromApi('remove', product_id).then(response => {
            if(response.status === 'success') {
                this._refreshSide(response.mini);
                this.options.updateCount(response.count);
                return true;
            } else {
                this.options.showError(response.message);
                return false;
            }
        }).catch(error => {
            console.log(error);
            return false;
        });
    }

    _increaseQuantity(product_id) {
        return this._getFromApi('increase', product_id).then(response => {
            if(response.status === 'success') {
                this._refreshSide(response.mini);
                this.options.updateCount(response.count);
                return true;
            } else {
                this.options.showError(response.message);
                return false;
            }
        }).catch(error => {
            console.log(error);
            return false;
        });
    }

    _decreaseQuantity(product_id) {
        return this._getFromApi('decrease', product_id).then(response => {
            if(response.status === 'success') {
                this._refreshSide(response.mini);
                this.options.updateCount(response.count);
                return true;
            } else {
                this.options.showError(response.message);
                return false;
            }
        }).catch(error => {
            console.log(error);
            return false;
        });
    }

    _refreshSide(mini_cart) {
        this.productContainer.innerHTML = '';
        this.productContainer.innerHTML = mini_cart;
        this._connectActions();
    }

    _connectActions() {
        let remove = this.productContainer.querySelectorAll('[data-element="remove"]');
        if(remove.length > 0) {
            remove.forEach(r => {
                r.addEventListener('click', this.removeFromCart);
            });
        }
        let increase = this.productContainer.querySelectorAll('[data-element="increase"]');
        if(increase.length > 0) {
            increase.forEach(i => {
                i.addEventListener('click', this.increaseCartQty);
            });
        }
        let decrease = this.productContainer.querySelectorAll('[data-element="decrease"]');
        if(decrease.length > 0) {
            decrease.forEach(d => {
                d.addEventListener('click', this.decreaseCartQty);
            });
        }
        let close = this.productContainer.querySelectorAll('[data-element="close"]');
        if(close.length > 0) {
            close.forEach(c => {
                c.addEventListener('click', this.closeSide);
            })
        }
    }

    _getFromApi(action, product_id = null) {
        const body_data = new FormData();
        body_data.append('action', this.actions[action]);
        if(product_id !== null) body_data.append('product_id', product_id);

        const body_params = new URLSearchParams(body_data);
        return fetch(this.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: body_params
        }).then((response) => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
}

export default Cart;